


































































import { Component, Prop, Vue } from 'vue-property-decorator';
import $ from 'jquery';
import InsLogo from '@/components/base/InsLogo.vue';
import InsLangSwitch from '@/components/business/header/InsLangSwitch.vue';
import InsMenu from '@/components/business/header/InsMenu.vue';
@Component({
  components: {
    'InsLogo': InsLogo,
    'InsLangSwitch': InsLangSwitch,
    'InsMenu': InsMenu
  }
})
export default class InsHeader extends Vue {
  private menuShow: boolean = false;

  getMenu () {
    this.$Api.promotion.getMenu().then((result) => {
      this.$store.dispatch('setHeaderMenus', result.ReturnValue.HeaderMenus);
      console.log(result.ReturnValue.HeaderMenus, 'result.ReturnValue.HeaderMenus');
      this.$store.dispatch('setFooterMenus', result.ReturnValue.FooterMenus);
    }).catch((error) => {
      console.log(error);
    });
  }

  showSlideMenu () {
    // this.menuShow = !this.menuShow;
    // this.$store.dispatch('isShowMenu', this.menuShow);
    let isShow = !JSON.parse(JSON.stringify(this.menuShow));
    this.$store.dispatch('isShowMenu', isShow);
    console.log('show');
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getMenu();
  }
  created () {
    addEventListener(
      'scroll',
      () => {
        var _this = this;
        let scrollTop =
          document.documentElement.scrollTop ||
          window.pageYOffset ||
          document.body.scrollTop;
        if (scrollTop >= 120) {
          $('.header-default').slideDown(300);
        } else {
          $('.header-default').fadeOut(300);
        }
      },
      false
    );
  }
}
