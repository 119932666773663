







import { Component, Vue, Watch } from 'vue-property-decorator';
import { FrontE } from '@/sdk/common/SysConst';

@Component
export default class App extends Vue {
  lang:string[] = ['E', 'C', 'S'];
  // 建站中跳转
  beforeCreate () {
    function GetRequest (urlStr) {
      var urlBuilding;
      if (typeof urlStr === 'undefined') {
        urlBuilding = decodeURI(location.search); // 获取url中"?"符后的字符串
      } else {
        urlBuilding = '?' + urlStr.split('?')[1];
      }
      var theRequest = {};
      if (urlBuilding.indexOf('?') !== -1) {
        var str = urlBuilding.substr(1);
        // alert(str);
        const strBuilding = str.split('&');
        for (var i = 0; i < strBuilding.length; i++) {
          theRequest[strBuilding[i].split('=')[0]] = (strBuilding[i].split('=')[1]);
        }
      }
      return theRequest;
    }
    // eslint-disable-next-line camelcase
    var parms_1 = GetRequest(window.location.search);
    // console.log(parms_1, 'parms_1');
    // alert(parms_1['returnUrl']);
    if (window.location.pathname === '/admin' || parms_1['returnUrl'] === 'admin') {
      this.$router.push('admin');
    } else if (((FrontE.pcBuilding && !this.$store.state.isMobile) || (FrontE.mobileBuilding && this.$store.state.isMobile)) && this.$route.name !== 'building') {
      this.$store.dispatch('setOrgPath', this.$route.path);
      this.$router.push('/building');
    }
  }

  beforeUpdate () {
    if (this.$route.query.returnUrl) {
      let url = this.$route.query.returnUrl + '?';
      Object.keys(this.$route.query).forEach((e) => {
        if (e !== 'returnUrl') url += e + '=' + this.$route.query[e] + '&';
      });
      this.$router.push(url);
    } else {
      this.$nextTick(() => {
        document.title = 'RSTCF(註冊專門行業承造商聯會)';
      });
    }
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    Vue.prototype.$ShowLayer();
    setTimeout(() => { Vue.prototype.$HiddenLayer(); }, 2000);
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    if (((FrontE.pcBuilding && !this.isMobile) || (FrontE.mobileBuilding && this.isMobile)) && this.$route.name !== 'building') {
      this.$store.dispatch('setOrgPath', this.$route.path);
      this.$router.push('/building');
    } else if (this.$route.name === 'building') {
      this.$router.push(this.$store.state.orgPath);
      this.$router.push('admin');
    }
  }
}
