const ana = require('./ApiAndAdminServer');
const AppId = '00d75813-cfc4-4910-9085-f04ad93cc4e9';
// const AdminServer = 'http://admin.dev.in-store.hk:84';
// const AdminServer = 'https://admin.uat.intimex.hk';
// const AdminServer = 'https://admin.uat.in-store.hk';
let AdminServer = ana.AdminServer;
let apiServer = ana.apiServer;
module.exports = {
  // instore app id admin的id
  AppId: AppId,
  // api server url 統一配置Api服務器的url
  ApiServer: apiServer,
  // ApiServer: 'https://api.uat.intimex.hk',
  // ApiServer: 'https://api.uat.in-store.hk',
  // api version for this app 統一配置Api版本號
  ApiVersion: 'V2',
  // api Authorization 身份认证，用于后端识别客户端
  Authorization: 'MDBkNzU4MTMtY2ZjNC00OTEwLTkwODUtZjA0YWQ5M2NjNGU5Ojk3MWRmYzEyLWQ1ODUtNDNkMi05Mzg2LTkwYWQzMWRjMzJmOQ==',
  // admin server url, for login management platform 統一配置admin服務器

  AdminServer: AdminServer,
  // url '/admin' auto open admin tab
  AutoOpenAdmin: true,
  // admin login url 統一配置admin登錄地址
  AdminLoginUrl: AdminServer + '/default/ClientLogin/' + AppId,
  // 前端默认配置
  FrontE: {
    defaultLang: 'C',
    mobileBuilding: false,
    pcBuilding: false
  }
  // slideMenu: {
  //   direction: 'left', // 菜單滑出方向 => 默認值 'top', 可選值 'top', 'left', 'right'
  //   width: '80%', // 菜單寬度 => 默認值'100%'，僅在position值為非'top'的情況生效
  //   height: '80%', // 菜單高度 => 默認值'100%'，僅在position值為'top'的情況生效
  //   Embedded: false, // 菜單是否內嵌 => 默認值 false ，僅在position值為非'top'的情況生效
  //   maskBg: '#000', // 側欄菜單遮罩顏色
  //   maskOpacity: '.3' // 側欄菜單遮罩顏色透明度
  // }
};
