










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component
export default class InsLogo extends Vue {
  get isMobile () {
    return this.$store.state.isMobile;
  }
}
