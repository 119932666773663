export default {
  Message: {
    Confirm: 'Ok',
    Cancel: 'Close'
  },
  DeliveryAddress: {
    Address: 'Address'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'PayNow'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: "Rm 306, 3 / f, tin's enterprise centre, 777 lai chi kok road, cheung sha wan (cheung sha wan MTR station exit B)"
  },
  Action: {
    ViewMore: 'View More',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :',
    date: 'date',
    Eventname: 'Eventname',
    Details: 'Details',
    place: 'place',
    Dateandtime: 'Dateandtime',
    Inquire: 'Inquire',
    Informationname: 'Informationname',
    Updatedate: 'Update date'
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Calendar: {
    KnowMore: 'Know More',
    Time: 'Time',
    Venue: 'Venue',
    NoEvent: 'No activity today'
  },
  photo: {
    photo: 'photo',
    BackText: 'Back to previous'
  },
  info: {
    Search: 'Search now…',
    Email: 'Email',
    Tel: 'Tel/Whatsapp',
    Addr: 'Sze Pai Shek, Kam Tin, Yuen Long, NT. (3 minutes’ walk from Kam Sheung Rd. MTR Station Exit A)'
  },
  Home: {
    Home: 'Home',
    Search: 'Search Results',
    ReadMore: 'Read More'
  }
};
